
import { Component, Vue } from 'vue-property-decorator';
import { updateAppItems, IUpdateProject, ICreateProject } from '@/api/items';

type IAppItem = IResponse.AppItems.IItem;

@Component({})
export default class ModalCreateForm extends Vue {
    public id: string = "";
    public visible: boolean = false;
    public form: any;
    public beforeCreate(): void {
        this.form = this.$form.createForm(this);
    }
    public changeVisible(appItem?: IAppItem): void {
        this.form = this.$form.createForm(this);
        this.visible = !this.visible;
        this.$nextTick(() => {
            this.form.resetFields();
            if (!appItem || !this.visible) {
                return;
            }
            this.initForm(appItem);
        })
    }
    public handleSubmit(e: Event): void {
        // e.preventDefault();
        this.form.validateFields(async (err: object, values: ICreateProject) => {
            if (!err) {
                const request: IUpdateProject = { id: this.id, ...values }
                try {
                    const response = await updateAppItems(request);
                    if (!response.status) {
                        this.$message.error(response.message);
                        return;
                    }
                    this.$message.success('Update success!');
                    this.refresh();
                    this.changeVisible();
                } catch (err) {
                    this.$message.error(err);
                }
            }
        });
    }
    public initForm(appItem: IAppItem): void {
        if (!appItem) {
            return;
        }
        this.form.setFieldsValue({
            items_name: appItem.items_name,
            type: appItem.type,
            description: appItem.description,
        })
        this.id = appItem.id;
    }
    public refresh(): void {
        this.$emit('refresh')
    }
    public checkData(rule: object, value: string, callback: (error?: any) => void) {
        if (value) {
            if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error('No Chinese input!'));
            }
        }
        callback();
    };
}
