
import { Component, Vue } from "vue-property-decorator";
import { getAppItems, deleteAppItems } from "@/api/items";
import ModalCreateForm from "@/pages/admin/appList/create/ModalCreateForm.vue";
import ModalUpdateForm from "@/pages/admin/appList/update/ModalUpdateForm.vue";
import config from "@/config";

type IAppItems = IResponse.AppItems.IItem;

@Component({
  components: {
    ModalCreateForm,
    ModalUpdateForm,
  },
})
export default class AppList extends Vue {
  public name: string = "AppList";
  public $refs!: {
    UpdateFormRef: ModalUpdateForm;
  };
  public appItems: IAppItems[] = [];
  public dataSource: IAppItems[] = [];
  public apiBaseUrl: string = config.apiBaseUrl;
  public appType: string = "android";

  public created(): void {
    this.initData();
  }
  public async initData(): Promise<void> {
    const response = await getAppItems();
    this.dataSource = response.list;
    this.switchType(this.appType);
  }
  public async deleteItem(id: string): Promise<void> {
    const response = await deleteAppItems(id);
    this.initData();
  }
  public showUpdate(appItem: IAppItems): void {
    this.$refs.UpdateFormRef.changeVisible(appItem);
  }
  public showDetail(appItem: IAppItems): void {
    this.$router.push({
      name: "appDetail",
      query: {
        id: appItem.id,
        name: appItem.items_name,
      },
    });
  }
  public switchType(type: string): void {
    if (type === "android") {
      this.appItems = this.dataSource.filter((item: IAppItems) => {
        return item.type === "1";
      });
    } else if (type === "mac") {
      this.appItems = this.dataSource.filter((item: IAppItems) => {
        return item.type === "2";
      });
    } else if (type === "window") {
      this.appItems = this.dataSource.filter((item: IAppItems) => {
        return item.type === "3";
      });
    } else {
      this.appItems = this.dataSource.filter((item: IAppItems) => {
        return item.type === "0";
      });
    }
  }
  public onTypeChange(): void {
    this.switchType(this.appType);
  }
}
