var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-radio-group',{on:{"change":_vm.onTypeChange},model:{value:(_vm.appType),callback:function ($$v) {_vm.appType=$$v},expression:"appType"}},[_c('a-radio-button',{attrs:{"value":"android"}},[_c('a-icon',{staticStyle:{"font-size":"15px"},attrs:{"type":"android"}}),_vm._v(" Android ")],1),_c('a-radio-button',{attrs:{"data-test":"ios-button","value":"ios"}},[_c('a-icon',{staticStyle:{"font-size":"15px"},attrs:{"type":"apple"}}),_vm._v(" Ios ")],1),_c('a-radio-button',{attrs:{"data-test":"mac-button","value":"mac"}},[_c('a-icon',{staticStyle:{"font-size":"15px"},attrs:{"type":"laptop"}}),_vm._v(" Mac ")],1),_c('a-radio-button',{attrs:{"data-test":"window-button","value":"window"}},[_c('a-icon',{staticStyle:{"font-size":"15px"},attrs:{"type":"windows"}}),_vm._v(" Window ")],1)],1),_c('ModalCreateForm',{on:{"refresh":_vm.initData}}),_c('ModalUpdateForm',{ref:"UpdateFormRef",on:{"refresh":_vm.initData}}),(_vm.appItems.length === 0)?_c('a-empty'):_c('a-row',{attrs:{"gutter":30}},_vm._l((_vm.appItems),function(item,index){return _c('a-col',{key:index,staticStyle:{"margin-bottom":"10px","min-width":"300px"},attrs:{"span":4}},[_c('a-card',{staticStyle:{"width":"300px"},attrs:{"data-test":"appCard","hoverable":""},on:{"click":function($event){return _vm.showDetail(item)}}},[_c('a-card-meta',[_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"slot":"avatar","type":item.type === '0'
                ? 'apple'
                : item.type === '1'
                ? 'android'
                : item.type === '2'
                ? 'laptop'
                : 'windows'},slot:"avatar"}),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('img',{staticClass:"appItem-icon",attrs:{"src":item.new_version.icon
                  ? item.new_version.icon
                  : item.type === '0' || item.type === '1'
                  ? require('@/assets/images/defaultApp.png')
                  : item.type === '2'
                  ? require('@/assets/images/defaultMac.png')
                  : require('@/assets/images/defaultWindows.png')}}),_vm._v(" "+_vm._s(item.items_name)+" ")]),_c('div',{staticStyle:{"height":"110px"},attrs:{"slot":"description"},slot:"description"},[_c('table',{staticClass:"appItem-info"},[_c('tr',{attrs:{"data-test":"address"}},[_c('td',{staticClass:"appItem-info-title"},[_vm._v("Address:")]),_c('td',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(`${_vm.apiBaseUrl}/upgrade?id=${item.id}`))]),_c('span',{staticClass:"appItem-info-appInfo nowrap"},[_vm._v(_vm._s(`${_vm.apiBaseUrl}/upgrade?id=${item.id}`))])],2)],1)]),_c('tr',{attrs:{"data-test":"bundleID"}},[_c('td',{staticClass:"appItem-info-title nowrap"},[_vm._v("Bundle ID:")]),_c('td',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.new_version.CFBundleIdentifier))]),_c('span',{staticClass:"appItem-info-appInfo nowrap"},[_vm._v(_vm._s(item.new_version.CFBundleIdentifier))])],2)],1)]),_c('tr',{attrs:{"data-test":"versionNum"}},[_c('td',{staticClass:"appItem-info-title nowrap"},[_vm._v("Latest version:")]),_c('td',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.new_version.versions_number))]),_c('span',{staticClass:"appItem-info-appInfo nowrap"},[_vm._v(_vm._s(item.new_version.versions_number))])],2)],1)]),_c('tr',{attrs:{"data-test":"description"}},[_c('td',{staticClass:"appItem-info-title"},[_vm._v("Description:")]),_c('td',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.description))]),_c('span',{staticClass:"appItem-info-appInfo nowrap"},[_vm._v(_vm._s(item.description))])],2)],1)])])])],1),_c('template',{slot:"actions"},[_c('a-icon',{key:"edit",attrs:{"data-test":"updateBtn","type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.showUpdate(item)}}}),_c('a-popconfirm',{attrs:{"title":"Are you sure to delete this App?","okText":"Yes","cancelText":"No"},on:{"confirm":() => {
                _vm.deleteItem(item.id);
              }}},[_c('a-icon',{key:"delete",attrs:{"data-test":"deleteBtn","type":"delete"},on:{"click":function($event){$event.stopPropagation();}}})],1)],1)],2)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }