
import { Component, Vue } from 'vue-property-decorator';
import { ICreateProject, createAppItems } from '@/api/items';

@Component({})
export default class ModalCreateForm extends Vue {
    public visible: boolean = false;
    public form: any;
    public beforeCreate(): void {
        this.form = this.$form.createForm(this);
    }
    public changeVisible(): void {
        this.visible = !this.visible;
        this.form.resetFields();
    }
    public handleSubmit(e: Event): void {
        // e.preventDefault();
        this.form.validateFields(async (err: object, values: ICreateProject) => {
            if (!err) {
                try {
                    const response = await createAppItems(values);
                    if (!response.status) {
                        this.$message.error(response.message);
                        return;
                    }
                    this.$message.success('Create success!');
                    this.refresh();
                    this.changeVisible();
                } catch (err) {
                    this.$message.error(err);
                }
            }
        });
    }
    public refresh(): void {
        this.$emit('refresh')
    }
    public checkData(rule: object, value: string, callback: (error?: any) => void) {
        if (value) {
            if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error('No Chinese input!'));
            }
        }
        callback();
    };
}
